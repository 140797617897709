import React from "react";
import { Helmet } from "react-helmet";
import IndexLayout from "./index";
import favicon from "../content/img/icon/favicon.ico";
import theme from "../util/theme";
import { graphql, useStaticQuery } from "gatsby";
import {
  GATSBY_FIREBASE_API_KEY,
  GATSBY_FIREBASE_APP_ID,
  GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_FIREBASE_DATABASE_URL,
  GATSBY_FIREBASE_MEASUREMENT_ID,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_SENDER_ID,
  GATSBY_FIREBASE_STORAGE_BUCKET,
  MIXPANEL_API_KEY,
} from "../util/variables";
import { brazeScript, embeddedablesScript, mixpanelScript } from "./scripts";
interface BasicLayout {
  title?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  themeColor?: string;
  runEmbeddedablesScript?: boolean;
  embeddedablesFlowId?: string;
  children: React.ReactNode;
}
export const BasicLayout: React.FC<BasicLayout> = ({
  title,
  noIndex,
  noFollow,
  children,
  themeColor,
  runEmbeddedablesScript,
  embeddedablesFlowId,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitterUsername
          image
          siteUrl
        }
      }
    }
  `);

  // console.log(data.site.siteMetadata);
  return (
    <IndexLayout>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WPQ2RBB');`}
        </script>
        {/* Firebase CDN script. Currently in place to use so embeddables can source */}
        {runEmbeddedablesScript && (
          <script async type="module" id="firebase-script">
            {`
              import { initializeApp } from "https://www.gstatic.com/firebasejs/9.1.0/firebase-app.js";
              import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from "https://www.gstatic.com/firebasejs/9.1.0/firebase-auth.js";
              import { getFunctions } from "https://www.gstatic.com/firebasejs/9.1.0/firebase-functions.js";
              // Your web app's Firebase configuration
              const firebaseConfig = {
                  apiKey: "${GATSBY_FIREBASE_API_KEY}",
                  authDomain: "${GATSBY_FIREBASE_AUTH_DOMAIN}",
                  databaseURL: "${GATSBY_FIREBASE_DATABASE_URL}",
                  projectId: "${GATSBY_FIREBASE_PROJECT_ID}",
                  storageBucket: "${GATSBY_FIREBASE_STORAGE_BUCKET}",
                  messagingSenderId: "${GATSBY_FIREBASE_SENDER_ID}",
                  appId: "${GATSBY_FIREBASE_APP_ID}",
                  measurementId: "${GATSBY_FIREBASE_MEASUREMENT_ID}"
              };
              // Initialize Firebase
              const app = initializeApp(firebaseConfig);
              const auth = getAuth(app);
              const functions = getFunctions(app);
              
              window.firebase = {
                app: app,
                auth: auth,
                functions: functions,
                GoogleAuthProvider: GoogleAuthProvider,
                signInWithPopup: signInWithPopup,
                OAuthProvider: OAuthProvider,
                createUserWithEmailAndPassword: createUserWithEmailAndPassword,
                signInWithEmailAndPassword: signInWithEmailAndPassword,
                signOut: signOut,
                updateEmail: updateEmail,
                updatePassword: updatePassword
              };
            `}
          </script>
        )}
        {/* braze script */}
        {runEmbeddedablesScript && (
          <script>
            {`
                ${brazeScript}
            `}
          </script>
        )}
        {/* stripe script */}
        {runEmbeddedablesScript && <script src="https://js.stripe.com/v3/" />}
        {/* mixpanel script */}
        {runEmbeddedablesScript && (
          <script>
            {`
            ${mixpanelScript}
          `}
          </script>
        )}
        {runEmbeddedablesScript && (
          <script>
            {`
            ${embeddedablesScript(embeddedablesFlowId ?? "")}
          `}
          </script>
        )}
        {runEmbeddedablesScript && (
          <script>
            {`
                mixpanel.init("${MIXPANEL_API_KEY}", {
                debug: true,
                track_pageview: true,
                persistence: "localStorage",
                });            
            `}
          </script>
        )}
        <script src="/onelinkScript.js" />
        <title>{title ? title : "Imprint: Learn Visually"}</title>
        <meta
          name="description"
          content="Imprint - The world’s most important knowledge, visualized."
        />
        <html lang="en-US" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="theme-color" content={themeColor ?? theme.PRIMARY_COLOR} />
        <meta charSet="UTF-8" />
        <meta name="og:image" content={data.site.siteMetadata.image} />
        <meta name="og:image:width" content="1000" />
        <meta name="og:image:height" content="667" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Imprint: Learn Visually" />
        <meta
          name="twitter:description"
          content="Imprint - The world’s most important knowledge, visualized."
        />
        {/* <meta name="twitter:image" content={data.site.siteMetadata.image} /> */}
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/know-d8bc0.appspot.com/imprint-website-preview-image.png"
        />

        {noIndex && noFollow ? (
          <meta name="robots" content="noindex,nofollow" />
        ) : noIndex || noFollow ? (
          <meta name="robots" content={noIndex ? "noindex" : "nofollow"} />
        ) : null}

        <link rel="canonical" href={data.site.siteMetadata.image.siteUrl} />
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
      </Helmet>
      {runEmbeddedablesScript && (
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: `<savvy id="flow_${embeddedablesFlowId}" />`,
          }}
        />
      )}
      {children}
    </IndexLayout>
  );
};

export const HappinessLayout: React.FC<BasicLayout> = ({ title, noIndex, children }) => (
  <IndexLayout>
    <Helmet>
      <title>{title ? title : "Imprint"}</title>
      <link rel="stylesheet" type="text/css" href="/global.css" />
      <link rel="icon" href={favicon} />
      <meta
        name="description"
        content="Imprint - The world’s most important knowledge, visualized."
      />
      <html lang="en-US" />
      <meta name="theme-color" content={theme.PRIMARY_COLOR} />
      <link rel="canonical" href="https://imprintapp.com/" />
      <meta charSet="UTF-8" />
      <meta name="og:image" content="/static/imprint-website-preview-image.png" />
      <meta name="og:image:width" content="1000" />
      <meta name="og:image:height" content="667" />
      {noIndex && <meta name="googlebot" content="noindex" />}

      {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap"
        rel="stylesheet"
      /> */}
    </Helmet>
    {children}
  </IndexLayout>
);
